import { template as template_55953377ced749f3b775e6224b74cf34 } from "@ember/template-compiler";
const FKText = template_55953377ced749f3b775e6224b74cf34(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
