import { template as template_56a342e0073749eeb81fd65a5bdd0fd8 } from "@ember/template-compiler";
const FKLabel = template_56a342e0073749eeb81fd65a5bdd0fd8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
